import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { bus } from "@/main.js";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default function useEmployeesList() {
    // Use toast
    const toast = useToast();


    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false },
        { key: "name", sortable: true },
        { key: "email", sortable: true },
        { key: "manager", sortable: false },
        { key: "role", sortable: false },
        { key: "status", sortable: false },
        { key: "actions" }
    ];
    const urlParams = new URLSearchParams(window.location.search)
    const filter = urlParams.get('q');
    const searchQuery = ref(filter ? filter : "");
    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const statusFilter = ref(null);
    const usersMetaData = ref([]);
    const filterByDesignationId = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, filterByDesignationId], () => {
        refetchData();
    });

    bus.$on("clearGlobalSearch", () => {
        searchQuery.value = "";
    });
    
    const fetchUsers = (ctx, callback) => {
        store
            .dispatch("app-employees/fetchEmployees", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                role: roleFilter.value ? roleFilter.value : '',
                status: statusFilter.value,
                filterByDesignationId: filterByDesignationId.value
            })
            .then(response => {
                const users = response.data.data;
                const { total } = response.data.meta;

                callback(users);
                totalUsers.value = total;
                usersMetaData.value = users;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching users list",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === "Employee") return "danger";
        if (role === "Team Lead") return "warning";
        if (role === "BBA Admin") return "success";
        if (role === "BBA Admin, Team Lead") return "info";
        return "primary";
    };

    const resolveUserRoleIcon = role => {
        if (role === "Employee") return "UserIcon";
        if (role === "Team Lead") return "SettingsIcon";
        if (role === "BBA Admin") return "DatabaseIcon";
        if (role === "Manager") return "ShieldIcon";
        if (role === "BBA Admin, Team Lead") return "ServerIcon";
        if (role === "Team Lead, Manager") return "ShieldIcon";
        return "UserIcon";
    };

    const resolveUserStatusVariant = status => {
        if (status === false) return "warning";
        if (status === true) return "success";
        return "primary";
    };

    return {
        fetchUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        usersMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,

        // Extra Filters
        roleFilter,
        statusFilter,
        filterByDesignationId,
    };
}
